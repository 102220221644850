import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import SampleForm from '../components/Form'
// import moneydialogue from '../img/money-dialogue.svg'
// import swish from '../img/swish.png'
// import swishw from '../img/swish-white.png'
// import empben from '../img/employer-benefits-icon.png'
import whitebranch from '../img/large-branch-white.png'
// import leaf from '../img/03-leaf.svg'
// import plant from '../img/001-plant.svg'
// import plants from '../img/02-plant-1.svg'
// import plantsoil from '../img/05-plant-2.svg'

class AppointmentPage extends React.Component {
  render() {
    const siteTitle = 'Thanks for Subscribing'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div class="appointment-page">
          <div class="container">
            <div class="col-md-12">
              <div class="bg text-center">
                <img alt="whitebranch" src={whitebranch} />
              </div>
              <h1 class="section-h text-center text-white">
                <em>Complete the form to schedule an Appointment</em>
              </h1>
              <p className="atf-p text-white text-center">
                This 15 minute phone call will give us both a chance to make
                sure your situation matches our experience. If we aren’t a good
                fit for each other we will gladly introduce you to a firm who is
                better suited to your needs.
              </p>
            </div>
          </div>

          <div className="newsletter appointment-form">
            <div class="container">
              <SampleForm
                form_name="Appointment Form"
                form_id="5e68893024fe5564e2a8da85"
                form_classname="form-newsletter"
                class="row justify-content-center"
                method="post"
              >
                <div className="form-row">
                  <div className="mb-3">
                    <div className="form-group">
                      <input
                        className="form-control c-form"
                        type="text"
                        name="Name"
                        id="full_name"
                        required
                      />
                      <label htmlFor="full_name">Enter Your Name</label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-group">
                      <input
                        className="form-control c-form"
                        type="date"
                        name="Date"
                        id="Date"
                        required
                      />
                    </div>
                    <small class="form-text text-white text-center">
                      Please enter preferred date.
                    </small>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <input
                        className="form-control c-form"
                        type="time"
                        name="Time"
                        id="Time"
                        required
                      />
                    </div>
                    <small class="form-text text-white text-center">
                      Please enter preferred time.
                    </small>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <input
                        className="form-control c-form"
                        type="text"
                        name="Phone"
                        id="phone"
                        required
                        maxlength="11"
                      />
                      <label htmlFor="phone">Phone</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <button
                        className="btn btn-primary bttn float-left c-button"
                        type="submit"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                  </div>
                </div>
              </SampleForm>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default AppointmentPage

export const AppointmentPageQuery = graphql`
  query AppointmentPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
